import React, { useState, useRef, useEffect } from 'react';
import { 
  Button, Typography, Box, Container, 
  Paper, CircularProgress, Alert, Select, MenuItem, FormControl, InputLabel
} from '@mui/material';

import { styled } from '@mui/material/styles';
import OpenAI from 'openai';
import ReactMarkdown from 'react-markdown';
import rehypeHighlight from 'rehype-highlight';
import AuditChecklistMapping from './audit_checklist';

// Custom styled components
const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const CustomFileInput = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
}));

const HiddenInput = styled('input')({
  display: 'none',
});

const FileInputButton = styled(Button)(({ theme }) => ({
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.primary.main}`,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const FileNameDisplay = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const MarkdownContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  '& pre': {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    overflowX: 'auto',
  },
  '& code': {
    fontFamily: 'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
  },
}));

function AssistantForm() {
  const [files, setFiles] = useState([]);
  const [output, setOutput] = useState(null);
  const [overallAssessmentOutcome, setOverallAssessmentOutcome] = useState(null);
  const [organizationIdentificationOutcome, setOrganizationIdentificationOutcome] = useState(null);
  const [systemProcessIdentificationOutcome, setSystemProcessIdentificationOutcome] = useState(null);
  const [timestampOutcome, setTimestampOutcome] = useState(null);
  const [responseData, setResponseData] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedControl, setSelectedControl] = useState('');

  const fileInputRef = useRef(null);
  const bottomRef = useRef(null);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  });
  
  useEffect(() => {
    setIsFormValid(files.length > 0 && selectedControl !== '');
  }, [files, selectedControl]);

  const handleFileChange = (event, setFiles) => {
    const newFiles = Array.from(event.target.files);
    setFiles(newFiles);
  };

  const handleControlChange = (event) => {
    setSelectedControl(event.target.value);
  };

  const handleFileButtonClick = (ref) => {
    ref.current.click();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setOutput('');
    setOverallAssessmentOutcome(null);
    setOrganizationIdentificationOutcome(null);
    setSystemProcessIdentificationOutcome(null);
    setTimestampOutcome(null);
  
    try {
      const openai = new OpenAI({
        apiKey: process.env.REACT_APP_OPENAI_API_KEY,
        dangerouslyAllowBrowser: true
      });
  
      // Step 1: Upload files
      const allFiles = files;
      if (allFiles.length === 0) {
        throw new Error("No files selected. Please upload files before submitting.");
      }
  
      console.log(`Uploading ${allFiles.length} files...`);
      const fileUploads = await Promise.all(
        allFiles.map(file => 
          openai.files.create({ 
            file, 
            purpose: 'assistants' 
          }).then(response => {
            console.log(`File uploaded: ${file.name}, ID: ${response.id}`);
            return response;
          })
        )
      );
      console.log(`${fileUploads.length} files uploaded successfully.`);
  
      // Step 2: Create a new thread
      console.log("Creating a new thread...");
      const thread = await openai.beta.threads.create();
      console.log("New thread created:", thread.id);
  
      // Step 3: Add a message to the thread with the prompt and file attachments
      console.log("Adding message to thread with file attachments...");
      var messageContent = `Here are my documents for the organizational control "${selectedControl}". The control states: "${AuditChecklistMapping[selectedControl]}". Please analyze the documents with respect to this control. Keep in mind this is a single request, I cannot respond after your response. Always post_result when done! `;
      console.log(`Prompt: ${messageContent}`)

      // Determine file types and create appropriate message content
      const messageContentArray = [{ type: "text", text: messageContent }];

      // Create an array of file attachments
      var attachments = fileUploads.map(file => {
        const fileType = file.filename.split('.').pop().toLowerCase();
        if (['jpg', 'jpeg', 'png', 'gif'].includes(fileType)) {
          messageContentArray.push({
            type: "image_file",
            image_file: { file_id: file.id }
          });
          return null
        }
        return {
          file_id: file.id,
          tools: [{type:"file_search"}]
        }
      });
      attachments = attachments.filter(att => att != null);
  
      // Combine text content and file attachments
      var messageParams = null;
      if (attachments.length > 0) {
        messageParams = {
          role: "user",
          content: messageContentArray,
          attachments
        };
      } else {
        messageParams = {
          role: "user",
          content: messageContentArray
        };
      }
  
      try {
        const message = await openai.beta.threads.messages.create(thread.id, messageParams);
        console.log("Message added to thread:", message.id);
      } catch (error) {
        console.error("Error adding message to thread:", error);
        throw error;
      }
  
      // Step 4: Run the assistant on this thread
      console.log("Running the assistant on the thread...");
      const run = await openai.beta.threads.runs.create(thread.id, {
        assistant_id: "asst_QuEokVVMgYvmyADv7jioYjDQ",
      });
      console.log("Run created:", run.id);
  
      // Poll for the run to complete
      let completedRun;
      while (true) {
        completedRun = await openai.beta.threads.runs.retrieve(thread.id, run.id);
        console.log("Run status:", completedRun.status);
        if (completedRun.status === 'completed') {
          break;
        } else if (completedRun.status === 'failed') {
          throw new Error("Run failed: " + completedRun.last_error.message);
        } else if (completedRun.status === 'requires_action') {
          // Handle required action
          const requiredAction = completedRun.required_action;
          if (requiredAction.type === 'submit_tool_outputs') {
            for (const toolCall of requiredAction.submit_tool_outputs.tool_calls) {
              if (toolCall.function.name === 'post_result') {
                const argument_json = JSON.parse(toolCall.function.arguments);
                console.log(argument_json);
                setOverallAssessmentOutcome(argument_json['overallAssessment']);
                setOrganizationIdentificationOutcome(argument_json['organizationIdentification']);
                setSystemProcessIdentificationOutcome(argument_json['systemProcessIdentification']);
                setTimestampOutcome(argument_json['timestamp']);
                setResponseData(argument_json);
                console.log("post_result function called with arguments:", toolCall.function.arguments);
                
                // Respond to the action
                await openai.beta.threads.runs.submitToolOutputs(thread.id, run.id, {
                  tool_outputs: [{
                    tool_call_id: toolCall.id,
                    output: JSON.stringify({ success: "true" })
                  }]
                });
                
                console.log("Responded to post_result action");
              }
            }
          }
        }
        await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second before polling again
      }
      console.log("Run completed");
  
      // Retrieve and display the assistant's response
      const messages = await openai.beta.threads.messages.list(thread.id);
      const lastAssistantMessage = messages.data
        .filter(message => message.role === 'assistant')
        .pop();
  
      if (lastAssistantMessage && lastAssistantMessage.content.length > 0) {
        setOutput(lastAssistantMessage.content[0].text.value);
      } else {
        setOutput("No response from the assistant.");
      }
  
      // Clean up: delete the uploaded files
      console.log("Cleaning up...");
      for (const file of fileUploads) {
        await openai.files.del(file.id);
      }
      console.log("Cleanup completed");
  
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      setOutput(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const renderFileNames = (files) => {
    if (files.length === 0) return 'No file chosen';
    return files.map(file => file.name).join(', ');
  };

  const getOverallAssessmentOutcomeAlert = () => {
    if (!responseData) {
      return null;
    }
    var message = responseData["reasoning"]["overall"]
    if (overallAssessmentOutcome == "ACCEPT") {
      return <Alert severity="success">{message}</Alert>
    }
    else if (overallAssessmentOutcome == "WARNING") {
      return <Alert severity="warning">{message}</Alert>
    }
    else if (overallAssessmentOutcome == "REJECT") {
      return <Alert severity="error">{message}</Alert>
    }    
    else {
      return null;
    }
  }

  const getOrganizationIdentificationOutcomeAlert = () => {
    if (!responseData) {
      return null;
    }
    var message = responseData["reasoning"]["organization"]
    if (organizationIdentificationOutcome == "ACCEPT") {
      return <Alert severity="success" style={{marginTop: 20}}>{message}</Alert>
    }
    else if (organizationIdentificationOutcome == "WARNING") {
      return <Alert severity="warning" style={{marginTop: 20}}>{message}</Alert>
    }
    else if (organizationIdentificationOutcome == "REJECT") {
      return <Alert severity="error" style={{marginTop: 20}}>{message}</Alert>
    }    
    else {
      return null;
    }
  }

  const getSystemProcessIdentificationOutcomeAlert = () => {
    if (!responseData) {
      return null;
    }
    var message = responseData["reasoning"]["systemProcess"]
    if (systemProcessIdentificationOutcome == "ACCEPT") {
      return <Alert severity="success" style={{marginTop: 20}}>{message}</Alert>
    }
    else if (systemProcessIdentificationOutcome == "WARNING") {
      return <Alert severity="warning" style={{marginTop: 20}}>{message}</Alert>
    }
    else if (systemProcessIdentificationOutcome == "REJECT") {
      return <Alert severity="error" style={{marginTop: 20}}>{message}</Alert>
    }    
    else {
      return null;
    }
  }

  const getTimestampOutcomeAlert = () => {
    if (!responseData) {
      return null;
    }
    var message = responseData["reasoning"]["timestamp"]
    if (timestampOutcome == "ACCEPT") {
      return <Alert severity="success" style={{marginTop: 20}}>{message}</Alert>
    }
    else if (timestampOutcome == "WARNING") {
      return <Alert severity="warning" style={{marginTop: 20}}>{message}</Alert>
    }
    else if (timestampOutcome == "REJECT") {
      return <Alert severity="error" style={{marginTop: 20}}>{message}</Alert>
    }    
    else {
      return null;
    }
  }

  return (
    <Container maxWidth="md">
      <Box sx={{ pt: 4, mb: 4 }}>
        <Paper elevation={3} sx={{ p: 4, borderRadius: 2, bgcolor: 'background.paper' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
            <img 
              src="logo.png" 
              alt="Cyduca Logo"
              style={{ maxWidth: '200px', height: 'auto' }}
            />
          </Box>
          <Typography variant="h4" component="h1" gutterBottom color="primary" fontWeight="bold" textAlign="center">
            NEN-EN-ISO_IEC 27002_2022
          </Typography>
          <Typography variant="body1" sx={{ mb: 4, textAlign: 'center', color: 'text.secondary' }}>
            Upload your documents and get an instant AI-generated quality analysis report.
          </Typography>
          <StyledForm onSubmit={handleSubmit}>
            <FormControl fullWidth>
              <InputLabel id="control-select-label">Select your organizational control</InputLabel>
              <Select
                labelId="control-select-label"
                id="control-select"
                value={selectedControl}
                label="Select your organizational control"
                onChange={handleControlChange}
              >
                {Object.keys(AuditChecklistMapping).map((control) => (
                  <MenuItem key={control} value={control}>{control}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <CustomFileInput>
              <HiddenInput
                type="file"
                ref={fileInputRef}
                onChange={(e) => handleFileChange(e, setFiles)}
              />
              <FileInputButton
                variant="outlined"
                component="span"
                onClick={() => handleFileButtonClick(fileInputRef)}
              >
                Upload supporting evidence
              </FileInputButton>
              <FileNameDisplay variant="body2">
                {renderFileNames(files)}
              </FileNameDisplay>
            </CustomFileInput>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              sx={{ mt: 2, fontWeight: 'bold' }}
              disabled={!isFormValid || isLoading}
            >
              {isLoading ? <CircularProgress size={24} color="inherit" /> : 'analyze supporting evidence'}
            </Button>
          </StyledForm>
        </Paper>
      </Box>

      {(output || isLoading) && (
        <Paper elevation={3} sx={{ p: 4, borderRadius: 2, bgcolor: 'background.paper', mb: 4 }}>
          <Typography variant="h5" component="h2" gutterBottom color="primary" fontWeight="bold" textAlign="center">
            Analysis Results
          </Typography>
          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
              <CircularProgress />
            </Box>
          ) : (
            <MarkdownContainer>
              {getOverallAssessmentOutcomeAlert()}
              {getOrganizationIdentificationOutcomeAlert()}
              {getSystemProcessIdentificationOutcomeAlert()}
              {getTimestampOutcomeAlert()}
              <ReactMarkdown rehypePlugins={[rehypeHighlight]}>
                {output}
              </ReactMarkdown>
            </MarkdownContainer>
          )}
          <div ref={bottomRef} />
        </Paper>
      )}
    </Container>
  );
}

export default AssistantForm;