import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import RecommendIcon from '@mui/icons-material/Recommend';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PaletteIcon from '@mui/icons-material/Palette';
import TranslateIcon from '@mui/icons-material/Translate';
import SchoolIcon from '@mui/icons-material/School';
import SecurityIcon from '@mui/icons-material/Security';
import CloudIcon from '@mui/icons-material/Cloud';
import CodeIcon from '@mui/icons-material/Code';

const items = [
  {
    icon: <RecommendIcon />,
    title: 'AI-Powered Recommendations',
    description:
      'Access AI-curated resources and templates aligned with compliance standards, and receive specific, actionable recommendations to enhance evidence quality.',
  },
  {
    icon: <NotificationsActiveIcon />,
    title: 'Alerts and Notifications',
    description:
      'Stay informed with notifications for compliance deadlines and automatic alerts for unusual patterns or potential compliance risks detected by AI.',
  },
  {
    icon: <PaletteIcon />,
    title: 'User-Friendly Interface with Custom Themes',
    description:
      'Enjoy a personalized experience with customizable dashboard layouts and color schemes, coupled with intuitive navigation for easy access to key features.',
  },
  {
    icon: <TranslateIcon />,
    title: 'Multilingual Support',
    description:
      'Access our AI evaluation tool in multiple languages and benefit from compliance evaluations adapted to local regulations and standards.',
  },
  {
    icon: <SchoolIcon />,
    title: 'Resource Center and Educational Content',
    description:
      'Enhance your knowledge with webinars, tutorials, and blog updates on compliance best practices and the latest trends in GRC and AI technologies.',
  },
  {
    icon: <SecurityIcon />,
    title: 'Secure',
    description:
      'We prioritize data confidentiality with secure access management, automated security checks, encryption, and real-time incident response management.',
  },
  {
    icon: <CloudIcon />,
    title: 'Cloud-First Design',
    description:
      'Access your GRC from anywhere, anytime with our service built on a bundle of IaaS, PaaS, and Infrastructure as Code technologies.',
  },
  {
    icon: <CodeIcon />,
    title: 'API Documentation and Developer Portal',
    description:
      'Integrate our tool seamlessly with comprehensive API documentation and sandbox access for testing functionalities in a controlled environment.',
  },
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: 'grey.900',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '80%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h3" sx={{ mb: 2 }}>
            Highlights
          </Typography>
          <Typography variant="h5" sx={{ color: 'grey.400' }}>
            Discover the powerful features that set our AI-powered GRC solution apart
          </Typography>
        </Box>
        <Grid container spacing={3}>
          {items.map((item, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Stack
                direction="column"
                component={Card}
                spacing={2}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'grey.800',
                  background: 'transparent',
                  backgroundColor: 'grey.900',
                }}
              >
                <Box sx={{ opacity: '50%', color: 'primary.main' }}>{item.icon}</Box>
                <div>
                  <Typography variant="h5" sx={{ mb: 1, color: 'primary.light' }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body1" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}