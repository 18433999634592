import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { 
  Button, Typography, Box, Container, 
  createTheme, ThemeProvider, CssBaseline, Paper, CircularProgress
} from '@mui/material';
import { styled } from '@mui/system';
import MarketingPage from './marketing-page/MarketingPage';
import DemoForm from './assistant-forms/DemoForm';
import AssistantForm from './assistant-forms/ISO_27002_2022';

// Import Montserrat font
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/700.css';

// Create a custom theme with the provided color palette and Montserrat font
const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat, "Montserrat Fallback", sans-serif',
    allVariants: {
      fontFamily: 'Montserrat, "Montserrat Fallback", sans-serif',
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#2874b7', // color_3
    },
    secondary: {
      main: '#2874b7', // color_3
    },
    text: {
      primary: 'rgba(0, 0, 0, 1)', // color_2
      secondary: 'rgba(102, 102, 102, 1)', // color_4
    },
    background: {
      default: 'rgba(247, 247, 247, 1)', // color_6
      paper: 'rgba(255, 255, 255, 1)', // color_1
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Montserrat Fallback';
          src: local('Arial');
        }
        * {
          font-family: 'Montserrat', 'Montserrat Fallback', sans-serif !important;
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '50px !important',
          textTransform: 'none',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          {/* Root route will show the MarketingPage */}
          <Route path="/" element={<MarketingPage />} />

          {/* /demo route will show the DemoForm */}
          <Route path="/demo" element={<DemoForm />} />
          <Route path="/ISO_27002_2022" element={<AssistantForm />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
