import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import MuiChip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';
import EdgesensorHighRoundedIcon from '@mui/icons-material/EdgesensorHighRounded';
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';

const automated_items = [
  {
    icon: <ViewQuiltRoundedIcon />,
    title: 'Interactive Upload',
    description:
      'Allow visitors to upload a sample document and receive an instant AI-generated quality analysis report.',
    imageLight: 'url("/static/images/templates/templates-images/dash-light.png")',
    imageDark: 'url("/static/images/templates/templates-images/dash-dark.png")',
  },
  {
    icon: <EdgesensorHighRoundedIcon />,
    title: ' Highlight Key Insights',
    description:
      'Use AI to highlight areas within the document that meet compliance standards and those that need improvement.',
    imageLight: 'url("/static/images/templates/templates-images/mobile-light.png")',
    imageDark: 'url("/static/images/templates/templates-images/mobile-dark.png")',
  },
  {
    icon: <DevicesRoundedIcon />,
    title: 'Scorecard',
    description:
      'Provide a visual score or rating system to indicate the overall quality of the evidence.',
    imageLight: 'url("/static/images/templates/templates-images/devices-light.png")',
    imageDark: 'url("/static/images/templates/templates-images/devices-dark.png")',
  },
];

const error_detection_items = [
  {
    icon: <ViewQuiltRoundedIcon />,
    title: 'Live Editing Assistance',
    description:
      'As users input or edit their documents, the AI provides real-time suggestions to enhance compliance and quality.',
    imageLight: 'url("/static/images/templates/templates-images/dash-light.png")',
    imageDark: 'url("/static/images/templates/templates-images/dash-dark.png")',
  },
  {
    icon: <EdgesensorHighRoundedIcon />,
    title: 'Real-Time Feedback and Suggestions',
    description:
      'Highlight inconsistencies, missing information, or compliance gaps instantly.',
    imageLight: 'url("/static/images/templates/templates-images/mobile-light.png")',
    imageDark: 'url("/static/images/templates/templates-images/mobile-dark.png")',
  }
];

const integration_items = [
  {
    icon: <ViewQuiltRoundedIcon />,
    title: 'Seamless GRC Integration',
    description:
      'Offer APIs or plugins that allow easy integration of your AI tool into existing GRC platforms.',
    imageLight: 'url("/static/images/templates/templates-images/dash-light.png")',
    imageDark: 'url("/static/images/templates/templates-images/dash-dark.png")',
  },
  {
    icon: <EdgesensorHighRoundedIcon />,
    title: 'Third-Party Compatibility',
    description:
      'Ensure compatibility with popular document management and cloud storage services like SharePoint, Google Drive, or Dropbox.',
    imageLight: 'url("/static/images/templates/templates-images/mobile-light.png")',
    imageDark: 'url("/static/images/templates/templates-images/mobile-dark.png")',
  },
];

const Chip = styled(MuiChip)(({ theme }) => ({
  variants: [
    {
      props: ({ selected }) => selected,
      style: {
        background:
          'linear-gradient(to bottom right, hsl(210, 98%, 48%), hsl(210, 98%, 35%))',
        color: 'hsl(0, 0%, 100%)',
        borderColor: theme.palette.primary.light,
        '& .MuiChip-label': {
          color: 'hsl(0, 0%, 100%)',
        },
        ...theme.applyStyles('dark', {
          borderColor: theme.palette.primary.dark,
        }),
      },
    },
  ],
}));

function MobileLayout({ selectedItemIndex, handleItemClick, selectedFeature }) {
  if (!automated_items[selectedItemIndex]) {
    return null;
  }

  return (
    <Box
      sx={{
        display: { xs: 'flex', sm: 'none' },
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Box sx={{ display: 'flex', gap: 2, overflow: 'auto' }}>
        {automated_items.map(({ title }, index) => (
          <Chip
            size="medium"
            key={index}
            label={title}
            onClick={() => handleItemClick(index)}
            selected={selectedItemIndex === index}
          />
        ))}
      </Box>
      <Card variant="outlined">
        <Box
          sx={(theme) => ({
            mb: 2,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            minHeight: 280,
            backgroundImage: 'var(--items-imageLight)',
            ...theme.applyStyles('dark', {
              backgroundImage: 'var(--items-imageDark)',
            }),
          })}
          style={
            automated_items[selectedItemIndex]
              ? {
                  '--items-imageLight': automated_items[selectedItemIndex].imageLight,
                  '--items-imageDark': automated_items[selectedItemIndex].imageDark,
                }
              : {}
          }
        />
        <Box sx={{ px: 2, pb: 2 }}>
          <Typography
            gutterBottom
            sx={{ color: 'text.primary', fontWeight: 'medium' }}
          >
            {selectedFeature.title}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1.5 }}>
            {selectedFeature.description}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
}

MobileLayout.propTypes = {
  handleItemClick: PropTypes.func.isRequired,
  selectedFeature: PropTypes.shape({
    description: PropTypes.string.isRequired,
    icon: PropTypes.element,
    imageDark: PropTypes.string.isRequired,
    imageLight: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  selectedItemIndex: PropTypes.number.isRequired,
};

export { MobileLayout };

export default function Features() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);
  const [selectedItemIndex2, setSelectedItemIndex2] = React.useState(0);
  const [selectedItemIndex3, setSelectedItemIndex3] = React.useState(0);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };


  const handleItemClick2 = (index) => {
    setSelectedItemIndex2(index);
  };


  const handleItemClick3 = (index) => {
    setSelectedItemIndex3(index);
  };

  const selectedFeature = automated_items[selectedItemIndex];
  const selectedFeature2 = error_detection_items[selectedItemIndex2];
  const selectedFeature3 = integration_items[selectedItemIndex3];

  return (
    <div>
      <Container id="features" sx={{ py: { xs: 8, sm: 16 } }} style={{paddingBottom: "10px"}}>
        <Box sx={{ width: { sm: '100%', md: '60%' } }}>
          <Typography
            component="h2"
            variant="h4"
            gutterBottom
            sx={{ color: 'text.primary' }}
          >
            Automated Evidence Quality Analysis
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: 'text.secondary', mb: { xs: 2, sm: 4 } }}
          >
            Our AI-powered system automatically analyzes and evaluates the quality of compliance evidence, ensuring thoroughness and accuracy in your GRC processes.
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row-reverse' },
            gap: 2,
          }}
        >
          <div>
            <Box
              sx={{
                display: { xs: 'none', sm: 'flex' },
                flexDirection: 'column',
                gap: 2,
                height: '100%',
              }}
            >
              {automated_items.map(({ icon, title, description }, index) => (
                <Box
                  key={index}
                  component={Button}
                  onClick={() => handleItemClick(index)}
                  sx={[
                    (theme) => ({
                      p: 2,
                      height: '100%',
                      width: '100%',
                      '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                      },
                    }),
                    selectedItemIndex === index && {
                      backgroundColor: 'action.selected',
                    },
                  ]}
                >
                  <Box
                    sx={[
                      {
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                        gap: 1,
                        textAlign: 'left',
                        textTransform: 'none',
                        color: 'text.secondary',
                      },
                      selectedItemIndex === index && {
                        color: 'text.primary',
                      },
                    ]}
                  >
                    {icon}

                    <Typography variant="h6">{title}</Typography>
                    <Typography variant="body2">{description}</Typography>
                  </Box>
                </Box>
              ))}
            </Box>
            <MobileLayout
              selectedItemIndex={selectedItemIndex}
              handleItemClick={handleItemClick}
              selectedFeature={selectedFeature}
            />
          </div>
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              width: { xs: '100%', md: '70%' },
              height: 'var(--items-image-height)',
            }}
          >
            <Card
              variant="outlined"
              sx={{
                height: '100%',
                width: '100%',
                display: { xs: 'none', sm: 'flex' },
                pointerEvents: 'none',
              }}
            >
              <Box
                sx={(theme) => ({
                  m: 'auto',
                  width: 420,
                  height: 500,
                  backgroundSize: 'contain',
                  backgroundImage: 'var(--items-imageLight)',
                  ...theme.applyStyles('dark', {
                    backgroundImage: 'var(--items-imageDark)',
                  }),
                })}
                style={
                  automated_items[selectedItemIndex]
                    ? {
                        '--items-imageLight': automated_items[selectedItemIndex].imageLight,
                        '--items-imageDark': automated_items[selectedItemIndex].imageDark,
                      }
                    : {}
                }
              />
            </Card>
          </Box>
        </Box>
      </Container>
      <Container id="features-2" sx={{ py: { xs: 8, sm: 16 } }} style={{paddingBottom: "10px"}}>
        <Box sx={{ width: { sm: '100%', md: '60%' } }}>
          <Typography
            component="h2"
            variant="h4"
            gutterBottom
            sx={{ color: 'text.primary' }}
          >
            Error Detection
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: 'text.secondary', mb: { xs: 2, sm: 4 } }}
          >
            Advanced algorithms detect and flag potential errors, inconsistencies, or areas of concern in your compliance documentation.
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row-reverse' },
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              width: { xs: '100%', md: '70%' },
              height: 'var(--items-image-height)',
            }}
          >
            <Card
              variant="outlined"
              sx={{
                height: '100%',
                width: '100%',
                display: { xs: 'none', sm: 'flex' },
                pointerEvents: 'none',
              }}
            >
              <Box
                sx={(theme) => ({
                  m: 'auto',
                  width: 420,
                  height: 500,
                  backgroundSize: 'contain',
                  backgroundImage: 'var(--items-imageLight)',
                  ...theme.applyStyles('dark', {
                    backgroundImage: 'var(--items-imageDark)',
                  }),
                })}
                style={
                  error_detection_items[selectedItemIndex2]
                    ? {
                        '--items-imageLight': error_detection_items[selectedItemIndex2].imageLight,
                        '--items-imageDark': error_detection_items[selectedItemIndex2].imageDark,
                      }
                    : {}
                }
              />
            </Card>
          </Box>
          <div>
            <Box
              sx={{
                display: { xs: 'none', sm: 'flex' },
                flexDirection: 'column',
                gap: 2,
                height: '100%',
              }}
            >
              {error_detection_items.map(({ icon, title, description }, index) => (
                <Box
                  key={index}
                  component={Button}
                  onClick={() => handleItemClick2(index)}
                  sx={[
                    (theme) => ({
                      p: 2,
                      height: '100%',
                      width: '100%',
                      '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                      },
                    }),
                    selectedItemIndex2 === index && {
                      backgroundColor: 'action.selected',
                    },
                  ]}
                >
                  <Box
                    sx={[
                      {
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                        gap: 1,
                        textAlign: 'left',
                        textTransform: 'none',
                        color: 'text.secondary',
                      },
                      selectedItemIndex2 === index && {
                        color: 'text.primary',
                      },
                    ]}
                  >
                    {icon}

                    <Typography variant="h6">{title}</Typography>
                    <Typography variant="body2">{description}</Typography>
                  </Box>
                </Box>
              ))}
            </Box>
            <MobileLayout
              selectedItemIndex={selectedItemIndex2}
              handleItemClick={handleItemClick2}
              selectedFeature={selectedFeature2}
            />
          </div>
        </Box>
      </Container>
      <Container id="features-3" sx={{ py: { xs: 8, sm: 16 } }}>
        <Box sx={{ width: { sm: '100%', md: '60%' } }}>
          <Typography
            component="h2"
            variant="h4"
            gutterBottom
            sx={{ color: 'text.primary' }}
          >
            Integration APIs and Plugins
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: 'text.secondary', mb: { xs: 2, sm: 4 } }}
          >
            Seamlessly integrate our solution with your existing GRC tools and workflows through robust APIs and user-friendly plugins.
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row-reverse' },
            gap: 2,
          }}
        >
          <div>
            <Box
              sx={{
                display: { xs: 'none', sm: 'flex' },
                flexDirection: 'column',
                gap: 2,
                height: '100%',
              }}
            >
              {integration_items.map(({ icon, title, description }, index) => (
                <Box
                  key={index}
                  component={Button}
                  onClick={() => handleItemClick3(index)}
                  sx={[
                    (theme) => ({
                      p: 2,
                      height: '100%',
                      width: '100%',
                      '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                      },
                    }),
                    selectedItemIndex3 === index && {
                      backgroundColor: 'action.selected',
                    },
                  ]}
                >
                  <Box
                    sx={[
                      {
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                        gap: 1,
                        textAlign: 'left',
                        textTransform: 'none',
                        color: 'text.secondary',
                      },
                      selectedItemIndex3 === index && {
                        color: 'text.primary',
                      },
                    ]}
                  >
                    {icon}

                    <Typography variant="h6">{title}</Typography>
                    <Typography variant="body2">{description}</Typography>
                  </Box>
                </Box>
              ))}
            </Box>
            <MobileLayout
              selectedItemIndex={selectedItemIndex3}
              handleItemClick={handleItemClick3}
              selectedFeature={selectedFeature3}
            />
          </div>
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              width: { xs: '100%', md: '70%' },
              height: 'var(--items-image-height)',
            }}
          >
            <Card
              variant="outlined"
              sx={{
                height: '100%',
                width: '100%',
                display: { xs: 'none', sm: 'flex' },
                pointerEvents: 'none',
              }}
            >
              <Box
                sx={(theme) => ({
                  m: 'auto',
                  width: 420,
                  height: 500,
                  backgroundSize: 'contain',
                  backgroundImage: 'var(--items-imageLight)',
                  ...theme.applyStyles('dark', {
                    backgroundImage: 'var(--items-imageDark)',
                  }),
                })}
                style={
                  integration_items[selectedItemIndex3]
                    ? {
                        '--items-imageLight': integration_items[selectedItemIndex3].imageLight,
                        '--items-imageDark': integration_items[selectedItemIndex3].imageDark,
                      }
                    : {}
                }
              />
            </Card>
          </Box>
        </Box>
      </Container>
    </div>
  );
}
